import timeFormatting from "../../utils/timeFormatting"
import { $addOrder,$ekaf,$orderConfirm,$payment,$paymentQuery,$getSalesPrice,$order,$paymentWechatconf  } from "../../api/home_marriage_test" 
import { $stat } from "../../api/stat"
const homeMarriageTest = {
    data () {
        return {
            popup_key:1,
            popup_text:'领取优惠红包',
            red_popup_text:'婚姻运势详批',
            percentage_num:0.7,
            complaint_buttom_fal:true, //投诉按钮开关
            man_value:'',//男姓名
            girl_value:'',//女姓名
            fixedbtn:false, //是否隐藏底部付费解锁全部内容按钮
            privacy_agreement_population:true,//隐私协议入口
            privacy_agreement:true, //隐私协议复选框
            privacy_agreement_company:"",//隐私协议中的公司名称
            lookrules:false, // 隐私协议 文本显示/隐藏
            currentDate:timeFormatting(),
            date_select_obj:null,
            boyShow:false,
            username: '',//姓名
            birth_date_value: '请选择出生时间',//出生时间
            typeface_style:'color:#000;opacity: 1;font-size:.26rem',//出生时间字体样式
            color_red:'color:#A90017;opacity: .5;',
            radio: '1',//性别 1是男2是女
            data_list:[], //动态用户列表数据
            counttime:10*60*1000,//红包倒计时
            red_packet_box_fal:false,//红包开关
            red_packet_box_fal2:false,//红包2开关
            red_packet_box_shade_fal:false,//红包遮罩
            shopPayConfirm:false,//请确认支付是否完成弹窗
            is_pay:'',
            price:{},
            scss_var_color:'#646464',
            scss_var_opacity:'.5',
            scss_var_input_color:'#000'
        }
    },
    created () {
        if ( localStorage.getItem('red_packet_type') === '2' ) {
            if (this.is_weixn) {
                let from_arr_ = ['dbpd','xfpd','xfpd2','zbpd','rmpd1','rmpd2','dgpd'];
                if ( from_arr_.indexOf(this.$route.query.from) === -1) {
                    this.popup_text = '测算我的婚姻运势'
                }else{
                    this.popup_text = '姓名测试'
                }
            }else{
                this.popup_text = '测算我的婚姻运势'
            }
        }else{
            this.popup_text = '领取优惠红包'
        }
       
        // if ( localStorage.getItem('red_packet_type') === null) {
           
        // }else{
        //     this.percentage_num = 0.7
        // }
        window.localStorage.removeItem('red_packet_type');
        window.localStorage.setItem('fromk', this.$route.query.from);
        // if (this.is_weixn) {
        //     if( this.$route.query.openid === undefined ) {
        //         // window.location.replace(`https://api.zhiyileiju.cn/api/v1/wechat/entry/${localStorage.getItem("fromk")}/3`)
        //         window.location.replace(`https://wechat.nnabc.cn/api/v1/wechat/entry/${localStorage.getItem("fromk")}/3`)
        //     }
        // }
        
        // console.log(this.$route.path)
        localStorage.setItem("is_hun_yin_",this.$route.path);
        // localStorage.setItem("type",'10')
        // window.localStorage.clear();//清除本地储存
        window.addEventListener("scroll", this.handleScroll);
        this.dataRequest()
        this.privacyAgreementCompany()
        clearInterval(localStorage.getItem("timer_payment"))
        localStorage.setItem("shopPayConfirm",false);
        this.redPacketCallbcak()
        if(this.$route.query.from == 'xm1' && this.$route.query.appkey){
            window.localStorage.setItem('query1', JSON.stringify(this.$route.query));
        }else{
            window.localStorage.removeItem("query1")
        }
        this.initStyle()
        $stat("v1_name_atching_index")
        
    },
    mounted(){
        if (window.history && window.history.pushState) {
            // 向历史记录中插入了当前页
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', this.goBack, false);
        }
        this.getWxConfig()
         this.$el.scrollTop = 0;      
        if (this.$route.query.uniqueKey) {
			window.localStorage.setItem("uniqueKey", this.$route.query.uniqueKey)
		}
        window.localStorage.setItem('fromk', this.$route.query.from);
    },
    computed:{
        is_weixn() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
            defaultOption() {
				return {
					step: 0.2, // 数值越大速度滚动越快
					limitMoveNum: this.data_list.length,
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: false, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				};
            },

    },
    methods:{ 
        goBack () {//后退事件
            console.log("点击了浏览器的返回按钮");
            // this.$router.go(0)
            this.$router.go(-1)
            // sessionStorage.clear();
            // window.history.back();
        },
        getWxConfig(){//微信内支付配置
            // let url = window.location.href;
                $paymentWechatconf({url:window.location.href}).then(res => {
                    console.log('wx',res.data.data.data)
                    let wxconfig = res.data.data.data;
                    if (this.is_weixn) {
                        wx.config({
                            debug: false,
                            appId: wxconfig.appId,
                            timestamp: wxconfig.timestamp,
                            nonceStr: wxconfig.nonceStr,
                            signature: wxconfig.signature,
                            jsApiList: ['chooseWXPay']
                        });
                    }
                })
        },
        handleScroll() {//y页面向下拉300时显示按钮
			var scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (scrollTop > 300) {
				this.fixedbtn = true;
			} else {
				this.fixedbtn = false;
			}
		},
        backToTheTopCallback () {//锚点
            $stat("v1_name_atching_anchor");
            document.querySelector("#agentTitle").scrollIntoView({behavior: "smooth", block: "start" });
        },
        initStyle () {//初始化样式
            console.log('13213',this.$route.path )
            switch (this.$route.path) {
                case '/home_marriage_test':
                    this.scss_var_color = '#A90017';
                    this.color_red = 'color:#A90017;opacity: .5;';
                    break; 
                case '/home_marriage_test_v2':
                    this.scss_var_color = '#525252';
                    this.color_red = 'color:#525252;opacity: .5;';
                    break;
                case '/home_marriage_test_v3':
                    this.scss_var_color = '#525252';
                    this.color_red = 'color:#525252;opacity: .5;';
                    break;
                case '/home_marriage_test_v3':
                    this.scss_var_color = '#525252';
                    this.color_red = 'color:#525252;opacity: .5;';
                    break;
                case '/name_matching':
                    this.scss_var_color = '#646464';
                    this.color_red = 'color:#646464;opacity: .5;';
                    break;
            
                default:
                    break;
            }
        },
        saveTheLinkCallback(){ //三次挽回链接
            if (this.is_weixn) {
                let from_arr_ = ['dbpd','xfpd','xfpd2','zbpd','rmpd1','rmpd2','dgpd'];
                let from_arr1_ = ['lndbgd','lnxf1','lnxf3','lnrt1','lnrt2'];
                switch ( -1 ) {
                    case from_arr_.indexOf(this.$route.query.from) === -1 ? from_arr_.indexOf(this.$route.query.from)+1 : from_arr_.indexOf(this.$route.query.from) - (from_arr_.indexOf(this.$route.query.from) + 1) :
                            window.location.replace(`https://jx2.gyqingqing.com/wechatmerry?fromk=hjgw3cm`)
                            //  https://jx2.gyqingqing.com/wechatmerry?fromk=ljgw3cm
                        break;
                    case from_arr1_.indexOf(this.$route.query.from) === -1 ? from_arr1_.indexOf(this.$route.query.from)+1 : from_arr1_.indexOf(this.$route.query.from) - (from_arr1_.indexOf(this.$route.query.from) + 1) :
                            window.location.replace(`https://jx2.gyqingqing.com/wechatmerry?fromk=ljgw3cm`)
                        break;
                
                    default:
                        window.location.replace(`https://wechat.nnabc.cn/api/v1/wechat/entry/${localStorage.getItem("fromk")}/4`)
                        break;
                }
            }else{
                window.location.replace(`https://huyin.zhiyileiju.cn/home_marriage_test?from=w3hc`)
                // window.location.replace(`https://huyin.zhiyileiju.cn/home_marriage_test?from=w3hc&kf=${res.data.data.hanzimean.xm}&sex=${res.data.data.sex === '男' ? '1': '2'}&birth=公历:${lunar_calendar_value.cYear}年${lunar_calendar_value.cMonth}月${lunar_calendar_value.cDay}日${lunar_calendar_value.tiem_}时&dateObj=${JSON.stringify(lunar_calendar_value)}`)
            }
        },
        dataRequest(){//数据请求
            let user_data = {
                type: 3,
                perPage: 10,
                page: 1,
                atype:2
            }
            $ekaf(user_data).then( ( res ) => {//用户动态列表
                this.data_list = res.data.data
            } )
            $getSalesPrice({fromk:this.$route.query.from}).then( ( res ) => {
                // console.log(res)
                this.price = res.data.data[0]
            })
             let payment_query_data_ = {
                paytype: 2,
                order_id: sessionStorage.getItem('user_id'),
            }
            //判断首页本地存储是否有order_id 没有就不请求$paymentQuery 接口，有，就请这个订单是否支付，如果支付就关闭红包
            if ( sessionStorage.getItem('user_id') === null) { //

            }else{
                $paymentQuery(payment_query_data_).then( ( res ) => {
                    // console.log(res)
                    if( res.data.data.is_pay === true ) {
                        this.red_packet_box_fal = false // 关闭红包
                        this.red_packet_box_shade_fal = false//关闭遮罩
                    }
                })
            }
        },
      
        chooseYourTimeOfBirthButtom(){//点击出生时间
            this.boyShow = true;
        },
        boyConfirm(data) {//出生日期确定
            this.date_select_obj = data
            this.birth_date_value = data.thisStr;
        },
        inputChange() {
            this.scss_var_input_color = '#000'
        },

        submitContentCallback () {//提交内容
            // this.$router.push({path:`${localStorage.getItem("is_hun_yin_")}/payment/${res.data.data.id}/${localStorage.getItem("type") === null ? 10 : localStorage.getItem("type") }`})
            // this.$router.push({path:`${localStorage.getItem("is_hun_yin_")}/payment/${"ZZZ"}/${localStorage.getItem("type") === null ? 10 : localStorage.getItem("type") }`})
            this.color_red = 'color:red;opacity: 1;'
            this.scss_var_color = 'red'
            this.scss_var_opacity = '1'
            if (this.man_value === '' ) {
                this.$toast('男方姓名填写不正确')
                
            }else if (this.man_value.length > 4 ) {
                this.$toast('男方姓名填写不正确')
                this.scss_var_input_color = 'red'
            }else if (this.man_value.length === 1 ) {
                this.$toast('男方姓名填写不正确')
                this.scss_var_input_color = 'red'
            }
            else if (this.girl_value === '' ) {
                this.$toast('女方姓名填写不正确')
                this.scss_var_input_color = 'red'
            }
            else if (this.girl_value.length > 4 ) {
                this.$toast('女方姓名填写不正确')
                this.scss_var_input_color = 'red'
            }
            else if (this.girl_value.length === 1) {
                this.$toast('女方姓名填写不正确')
                this.scss_var_input_color = 'red'
            }
            
            else{
                let data_ = {
                    birth_state: 1,
                    name: this.man_value,
                    pair_name:this.girl_value,
					xname: this.man_value.slice(0,1),
					sex: '1',
					pub_birth_date: "2021-12-8 00:00:00",
					order_type: 1,
					fromk: this.$route.query.from,
                    pageUrl: window.location.href,
                    // this.$route.query.from
                    openid:this.$route.query.openid === undefined ? '' : this.$route.query.openid,
                    kf:this.$route.query.greenkf === undefined ? '' : this.$route.query.greenkf, 
                    // man_value
                    // girl_value
                }
                $addOrder(data_).then( ( res ) => {
                    console.log('res',res)
                    if ( res.status === 200) {
                        localStorage.setItem("time","600000");//存倒计时10分钟单位毫秒
                        if ( this.$route.query.greenkf === undefined ) {
                            this.$router.push({path:`${localStorage.getItem("is_hun_yin_")}/payment/${res.data.data.id}/${localStorage.getItem("type") === null ? 10 : localStorage.getItem("type") }`})
                        }else{
                            this.$router.push({path:`${localStorage.getItem("is_hun_yin_")}/payment/result/${res.data.data.id}/${localStorage.getItem("type") === null ? 10 : localStorage.getItem("type") }`})
                        }

                        // this.$router.push({path:`${localStorage.getItem("is_hun_yin_")}/payment/${res.data.data.id}/${localStorage.getItem("type") === null ? 10 : localStorage.getItem("type") }`})
                        localStorage.setItem("type",10);
                    }
                }).catch((err)=> {
                    // console.log('err',err)
                    this.$toast('姓名填写不正确')
                    this.scss_var_input_color = 'red'
                })
                
                // console.log(localStorage.getItem("is_hun_yin_").substr(1))
                
            }
        },
        submitButton () {//提交 
            $stat("v1_name_atching_top_subbtn");
            if ( this.privacy_agreement !== true ) {
                this.$dialog.confirm({
                    title: '提示',
                    message: '请先同意隐私协议',
                    confirmButtonText:'同意'
                })
                .then(() => {
                    this.privacy_agreement = true
                    this.submitContentCallback()
                })
                .catch(() => {
                    // on cancel
                });
            }else{
                this.submitContentCallback()
            }

            
            
        },
        privacyAgreementExamineButtom () {//隐私协议查看按钮
            this.lookrules = true
        },
        privacyAgreementCompany () {//隐私协议中，不同渠道展示不同公司名称
        let id = this.$route.query.from
            switch ( true ) {
                case id === 'xa1' || id === 'xa2'|| id === 'xa3' :
                   this.privacy_agreement_company = '湖南亮亮文化传媒有限公司'
                    break;
                case id === 'xb1' || id === 'xb2' || id === 'xb3' :
                   this.privacy_agreement_company = '长沙亚汰信息咨询有限公司'
                    break;
                case id === 'xc1' || id === 'xc2' || id === 'xc3' || id === 'xc4' || id === 'xs1'|| id === 'xs2'|| id === 'xs3':
                   this.privacy_agreement_company = '武汉道森梯媒文化传播有限公司'
                    break;
                case id === 'xd1':``
                   this.privacy_agreement_company = '山东纷享团电子商务有限公司'
                    break;
                case id === 'xe1':
                   this.privacy_agreement_company = '深圳市大白网络科技有限公司'

                case id === 'xf2' || id === 'xf1'|| id === 'xf3'|| id === 'xf4' || id === 'dz1' || id === 'dz2':
                   this.privacy_agreement_company = '杭州智以类聚科技有限公司'
                   break;
                case id === 'xr1' || id === 'xr2':
                   this.privacy_agreement_company = '上海容铭信息科技有限公司'
                   break;
                case id === 'xq1' || id === 'xq2'|| id === 'xq3' || id === 'dc1'|| id === 'dc2'|| id === 'dc3':
                   this.privacy_agreement_company = '杭州其大无外网络科技有限公司'
                   break;
                case id === 'xn1' || id === 'xn2'|| id === 'xn3'|| id === 'xn4'|| id === 'xn5' || id === 'xj1' :
                   this.privacy_agreement_company = '绍兴恒健智能科技有限公司'
                   break;
                case id === '10':
                    if ( localStorage.getItem("is_hun_yin_") === '/home_marriage_test_v2') {
                        this.privacy_agreement_company = '绍兴恒健智能科技有限公司'
                    }
                   break;
                case id === 'xt1' || id === 'xt2' || id === 'xx1' || id === 'db1' || id === 'db2' || id === 'db3' || id === 'db4' || id === 'db5'|| id === 'db6' :
                    this.privacy_agreement_company = '图易(绍兴)信息技术有限公司'
                    break;
                case id === 'xs4' || id === 'xp1' :
                    this.privacy_agreement_company = '武汉圈谱文化传播有限公司'
                    break;
                case id === 'dt1'  :
                    this.privacy_agreement_company = '图易（武汉）信息技术有限公司 '
                    break;
                case id === 'dq1'  :
                    this.privacy_agreement_company = '贵阳青青互联网科技有限公司 '
                    break;
                case id === 'dj1'  :
                    this.privacy_agreement_company = '重庆庚霖网络科技有限公司'
                    break;
                    
                default:
                    //如果不是以上渠道就隐藏隐私协议入口
                    // this.privacy_agreement_population = false‘
                    break;
            }
        },
        employRedPacket (type) {//使用红包
            if ( type === '') { 
                type = window.localStorage.getItem('red_packet_type');
            }
            window.localStorage.setItem('red_packet_type',type);
            if ( type === '1') {
                $stat("v1_name_atching_red_packet_small")
            }else if ( type === '2'){
                $stat("v1_name_atching_red_packet_big")
            }

            if ( localStorage.getItem('red_packet_type') === '2' ) {
                if (this.is_weixn) {
                    let from_arr_ = ['dbpd','xfpd','xfpd2','zbpd','rmpd1','rmpd2','dgpd'];
                    if ( from_arr_.indexOf(this.$route.query.from) === -1) {
                        this.popup_text = '测算我的婚姻运势'
                    }else{
                        this.popup_text = '姓名测试'
                    }
                }else{
                    this.popup_text = '测算我的婚姻运势'
                }
            }else{
                this.popup_text = '领取优惠红包'
            }

            let orderConfirm_data__  = {
                order_id: sessionStorage.getItem('user_id'),
                paytype: 2,
                discount_id: 1,
                discount_type:type,
            }
            // if ( type === '1') {
            //     orderConfirm_data__.
            // }
            localStorage.setItem("type",2); //将支付类型存到本地
            $orderConfirm(orderConfirm_data__).then( ( res ) => {
                // console.log(res)
                if( res.status === 200 ) {
                    if (this.is_weixn) {
					    $payment(localStorage.getItem("is_hun_yin_").substr(1),sessionStorage.getItem('user_id')).then( ( res ) => {
                            let pay = res.data.data;
                            wx.ready(function() {
                                wx.chooseWXPay({
                                    timestamp: pay.timeStamp,
                                    nonceStr: pay.nonceStr,
                                    package: pay.package,
                                    signType: pay.signType,
                                    paySign: pay.paySign, // 支付签名
                                    success: function(res) {
                                        let arr_from_ = ['hytonp1','nptohy1','kppd','cdpd','kphy','cdhy']
                                        if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                                            window.location.replace(`/name_matching/payment/succeed/${sessionStorage.getItem('user_id')}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                                        }else{
                                            //直接跳结果
                                            window.location.replace(`/name_matching/payment/result/${sessionStorage.getItem('user_id')}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                                        }
                                        window.location.replace(`/name_matching/payment/succeed/${sessionStorage.getItem('user_id')}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                                    }
                                });
                            });
                            // this.shopPayConfirm = true
                        })
					} else {
                        // console.log('payment_type',payment_type)
						// location.href = `https://api.zhiyileiju.cn/api/v1/payment/${payment_type === 'wechat'? 'wechathy' :payment_type}/${this.$route.params.id}/${localStorage.getItem("is_hun_yin_").substr(1)}`;
                        let t = this;
                        location.href = `https://api.zhiyileiju.cn/api/v1/payment/wechat/${sessionStorage.getItem('user_id')}`;
                        // location.href = `https://api.zhiyileiju.cn/api/v1/payment/alipay/${sessionStorage.getItem('user_id')}`;
                        localStorage.setItem("type",2);
					}
                }
                this.redPacketClose()
                if ( localStorage.getItem('red_packet_type') === '2' ) {
                    // this.popup_text = '测算我的婚姻运势'
                    if (this.is_weixn) {
                        let from_arr_ = ['dbpd','xfpd','xfpd2','zbpd','rmpd1','rmpd2','dgpd'];
                        if ( from_arr_.indexOf(this.$route.query.from) === -1) {
                            this.popup_text = '测算我的婚姻运势'
                        }else{
                            this.popup_text = '姓名测试'
                        }
                    }else{
                        this.popup_text = '测算我的婚姻运势'
                    }
                }
                    this.shopPayConfirm = true
            })
        },
        cruelTefusedToButtom() { //残忍拒绝
            $stat("v1_name_atching_red_packet_small_refuse")
            
            if (this.is_weixn) {
                let from_arr_ = ['dbpd','xfpd','xfpd2','zbpd','rmpd1','rmpd2','dgpd'];
                if ( from_arr_.indexOf(this.$route.query.from) === -1) {
                    this.red_popup_text = '婚姻运势详批'
                }else{
                    this.red_popup_text = '姓名测试'
                }
            }else{
                this.red_popup_text = '婚姻运势详批'
            }

            this.red_packet_box_fal = false //关闭红包
            this.red_packet_box_fal2 = true //开启红包2
            // this.red_packet_box_shade_fal = true //开启红包2

        },
        browseWithMore() {//浏览跟多
            $stat("v1_name_atching_red_packet_big_more")
            $order(sessionStorage.getItem('user_id')).then( ( res ) => {
                console.log(res.data.data)
                let date_ =  (res.data.data.pub_birth_date.substring(0,10).split('-'))
                let items_ = res.data.data.pub_birth_date.substr(11,2)
                console.log(items_)
                let lunar_calendar_value = {
                    cYear:date_[0],
                    cMonth:date_[1],
                    cDay:date_[2],
                    tiem_:items_
                }
                sessionStorage.removeItem('user_id')
                //线上
                if (this.is_weixn) {
                        let from_arr_ = ['dbpd','xfpd','xfpd2','zbpd','rmpd1','rmpd2','dgpd'];
                        let from_arr1_ = ['lndbgd','lnxf1','lnxf3','lnrt1','lnrt2'];
                        switch ( -1 ) {
                            case from_arr_.indexOf(this.$route.query.from) === -1 ? from_arr_.indexOf(this.$route.query.from)+1 : from_arr_.indexOf(this.$route.query.from) - (from_arr_.indexOf(this.$route.query.from) + 1) :
                                    window.location.replace(`https://jx2.gyqingqing.com/wechatmerry?fromk=hjgw3cm`)
                                    //  https://jx2.gyqingqing.com/wechatmerry?fromk=ljgw3cm
                                break;
                            case from_arr1_.indexOf(this.$route.query.from) === -1 ? from_arr1_.indexOf(this.$route.query.from)+1 : from_arr1_.indexOf(this.$route.query.from) - (from_arr1_.indexOf(this.$route.query.from) + 1) :
                                    window.location.replace(`https://jx2.gyqingqing.com/wechatmerry?fromk=ljgw3cm`)
                                break;
                        
                            default:
                                window.location.replace(`https://wechat.nnabc.cn/api/v1/wechat/entry/${localStorage.getItem("fromk")}/4`)
                                break;
                        }
                }else{
                    window.location.replace(`https://huyin.zhiyileiju.cn/home_marriage_test?from=w3hc`)
                    // window.location.replace(`https://huyin.zhiyileiju.cn/home_marriage_test?from=w3hc&kf=${res.data.data.hanzimean.xm}&sex=${res.data.data.sex === '男' ? '1': '2'}&birth=公历:${lunar_calendar_value.cYear}年${lunar_calendar_value.cMonth}月${lunar_calendar_value.cDay}日${lunar_calendar_value.tiem_}时&dateObj=${JSON.stringify(lunar_calendar_value)}`)
                }
                // window.open(`/home_marriage_test?from=w3hc`)
                //本地

            })
           

            this.redPacketClose()
        },
        redPacketCallbcak () {//红包显示
            // console.log(sessionStorage.getItem("user_id"))
            if( sessionStorage.getItem("user_id") === null ) {
                this.red_packet_box_fal = false
                this.red_packet_box_shade_fal = false
            }else{
                if ( localStorage.getItem("judge_indicates_which") === '1') {
                    this.red_packet_box_fal = false //关闭红包
                    this.red_packet_box_fal2 = true //开启红包2
                    this.red_packet_box_shade_fal = true
                }else{
                    this.red_packet_box_fal = true
                    this.red_packet_box_shade_fal = true
                }
            }
        },
        redPacketClose () {//红包关闭
            localStorage.removeItem("judge_indicates_which")
            this.red_packet_box_fal = false
            this.red_packet_box_fal2 = false
            this.red_packet_box_shade_fal = false
        },
        dcheckorder () { //订单确认
            let payment_query_data_ = {
                paytype: 2,
                order_id: sessionStorage.getItem('user_id'),
            }
            $paymentQuery(payment_query_data_).then( ( res ) => {
                // console.log(res)
                this.is_pay = res.data.data.is_pay
                if( this.is_pay ) {
                    let arr_from_ = ['hytonp1','nptohy1','kppd','cdpd','kphy','cdhy']
                    if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                        window.location.replace(`/name_matching/payment/succeed/${sessionStorage.getItem('user_id')}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                    }else{
                        //直接跳结果
                        window.location.replace(`/name_matching/payment/result/${sessionStorage.getItem('user_id')}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                    }
                    // window.location.replace(`/name_matching/payment/succeed/${sessionStorage.getItem("user_id")}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                }else{
                    // this.$toast.fail("当前订单未支付");
                    this.employRedPacket('')
                }
            }).catch ( ( err ) => {
                console.log('err',err)
                this.employRedPacket('')
                // this.shopPayConfirm = false
                // this.$toast.fail("当前订单未支付");

            } )
        },
        unpay () {//关闭确认弹窗
          
            if ( localStorage.getItem('red_packet_type') === null) {
               
            }else{
                this.percentage_num = 0.7
            }
            this.popup_key++
            localStorage.setItem("judge_indicates_which",localStorage.getItem('red_packet_type'));
            if ( localStorage.getItem('red_packet_type') === '2' ) {
                window.localStorage.removeItem('red_packet_type');
                window.sessionStorage.removeItem('user_id');
                // window.location.replace(`/home_marriage_test?from=w3hc`)
                // if (this.is_weixn) {
                //     let from_arr_ = ['dbpd','xfpd','xfpd2','zbpd','rmpd1','rmpd2','dgpd'];
                //     if ( from_arr_.indexOf(this.$route.query.from) === -1) {
                //         window.location.replace(`https://wechat.nnabc.cn/api/v1/wechat/entry/${localStorage.getItem("fromk")}/4`)
                //     }else{
                //         window.location.replace(`https://jx2.gyqingqing.com/wechattestname?fromk=hjgw3cm`)
                //     }
                // }else{
                //     window.location.replace(`/home_marriage_test?from=w3hc`)
                // }
                this.saveTheLinkCallback()
            }else{
                this.$router.push('/'+this.$route.path.split('/')[1])
                this.shopPayConfirm = false
                location.reload();
            }
        },
        complaintButtom() {
            $stat("v1_name_atching_complaint");
            window.location.href = 'https://www.wjx.top/vj/PfjwFNW.aspx'
        }       
    },
    destroyed () {
        window.removeEventListener('popstate', this.goBack, false);
    },
}

export default homeMarriageTest